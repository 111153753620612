import { useQuery } from "@tanstack/react-query";
import { getLines } from "../../api-clients";
import { useHighlightEvent } from "./useHighlightEvent";

export function useDocSearch(doc: {
  ticker: string;
  form: string;
  year: number;
}) {
  const { detail: range } = useHighlightEvent();
  const rangeQuery = useQuery({
    queryKey: ["doc-range", { doc, range }],
    queryFn: async () => getLines(doc.ticker, doc.form, doc.year, range!),
    enabled: Boolean(range),
  });
  return {
    range,
    lines: range ? rangeQuery.data?.lines || [] : [],
  };
}
