import { Empty, Flex, Table, theme, Tree } from "antd";
import { useContext, useEffect, useState } from "react";
import { useDocViewer } from "./useDocViewer";
import { viewerContext } from "./useViewerApp";
import { DataItem, FootnoteResult, TableResult } from "../../api-clients/def14a";

export function TracingPanel() {
  const { token } = theme.useToken();
  const context = useContext(viewerContext);
  const hasDoc = Boolean(context.current.doc);
  return hasDoc ? (
    <TracingViewerPanel />
  ) : (
    <Flex
      align="center"
      justify="center"
      style={{
        height: "100%",
        backgroundColor: `${token.colorBgLayout}99`,
      }}
    >
      <Empty description="No filing selected" />
    </Flex>
  );
}


const allTables = (footnote: FootnoteResult): TableResult[] => {
  const res = [...(footnote.tables || [])];
  const nested = (footnote.tables || []).map(t => (t.parsedFootnotes || []).map(f => allTables(f))).flat(2)
  return [...res, ...nested]
}

const allFootnotes = (footnote: FootnoteResult): FootnoteResult[] => {
  const res = [footnote]
  const nested = (footnote.tables || []).map(t => (t.parsedFootnotes || []).map(f => allFootnotes(f))).flat(2)
  return [...res, ...nested]
}

function TracingViewerPanel() {
  const { token } = theme.useToken();
  const context = useContext(viewerContext);
  const {highlight} = context
  if (!context.current.doc) throw new Error("Expected doc");
  const viewer = useDocViewer(context.current.doc);


  useEffect(()=>{
    const r = viewer.result
    if (r){
      const all = [r.sct, ...r.otherComp.footnotes.flatMap(f => allTables(f)), r.otherComp.otherContent.table].filter(Boolean) as TableResult[]
      const locations = [
        r.sct.location,
        ...r.sct.footnotes.flatMap(f => f.locations),
        ...all.map(t => t.location),
        ...all.map(t => t.footnotes.map(f => f.locations)).flat(2)
      ]
      highlight(locations)
    }
  }, [viewer.result, highlight])

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        overflow: "scroll",
        backgroundColor: token.colorBgLayout,
        gap: 10,
      }}
    >
      <div>
        <h1>footnotes</h1>
        <FootnoteTable />
      </div>
      <div>
        <DataItemTree />
        <h1>other comp tables</h1>
        <ParsedTable />
      </div>
    </div>
  );
}

function ParsedTable() {
  const context = useContext(viewerContext);
  if (!context.current.doc) throw new Error("Expected doc");

  const viewer = useDocViewer(context.current.doc);
  const setFilteredInfo = useState({} as any)[1];
  const [sortedInfo, setSortedInfo] = useState({} as any);

  const result = viewer.result;
  if (!result) {
    return <div>No result yet...</div>;
  }
  const all = [ ...result.otherComp.footnotes.flatMap(f => allTables(f)), result.otherComp.otherContent.table].filter(Boolean) as TableResult[]

  const datapoints = all
    .map((t) => {
      const headers = t.parsed.headers
        .map((h) => h.value)
        .filter((d) => !["Name", "Year"].includes(d));
      return t.parsed.datarows.map((d) => {
        const Name = d.Name.value;
        const Year = d.Year.value;
        return headers.map((h) => ({
          Name,
          Year,
          Amount: d[h]?.value,
          Column: h,
          mapping: (t.mapping || {})[h],
          location: t.location
        }));
      });
    })
    .flat(2)
    .map((r, idx) => ({ ...r, key: idx }));

  const names = [...new Set(datapoints.map((d) => d.Name))];
  const mappings = [...new Set(datapoints.map((d) => d.mapping))] as string[]

  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      filters: names.map((n) => ({ text: n, value: n })),
      onFilter: (value: any, record: any) => record.Name === value,
    },
    {
      title: "Year",
      dataIndex: "Year",
      key: "Year",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
      sorter: (a: any, b: any) =>
        coerceNumber(a.Amount) - coerceNumber(b.Amount),
      sortOrder: sortedInfo.columnKey === "Amount" ? sortedInfo.order : null,
    },
    {
      title: "Column",
      dataIndex: "Column",
      key: "Column",
    },
    {
      title: "mapping",
      dataIndex: "mapping",
      key: "mapping",
      sorter: (a: any, b: any) => a.mapping.localeCompare(b.mapping),
      sortOrder: sortedInfo.columnKey === "mapping" ? sortedInfo.order : null,
      filters: mappings.map((n) => ({ text: n, value: n })),
      onFilter: (value: any, record: any) => record.mapping === value,
    },
  ];

  const datasource = datapoints;
  return (
    <Table
      dataSource={datasource}
      columns={columns}
      onChange={(pagination, filters, sorter) => {
        console.log("Various parameters", pagination, filters, sorter);
        setFilteredInfo(filters);
        setSortedInfo(sorter);
      }}
      pagination={{
        pageSize: 15, // Set the default pageSize to 20
        total: datasource.length, // Total number of items
      }}
      onRow={(record) => ({
        onClick: () => {context.hover([record.location])},
        style: { cursor: 'pointer' },
      })}
    />
  );
}

const coerceNumber = (a: string | number) => {
  const res = Number((a + "").replaceAll(",", ""));
  return Number.isNaN(res) ? 0 : res;
};

function FootnoteTable() {
  const context = useContext(viewerContext);
  if (!context.current.doc) throw new Error("Expected doc");

  const viewer = useDocViewer(context.current.doc);
  const setFilteredInfo = useState({} as any)[1];
  const [sortedInfo, setSortedInfo] = useState({} as any);

  const result = viewer.result;
  if (!result) {
    return <div>No result yet...</div>;
  }

  const all = [...result.otherComp.footnotes.flatMap(f => allFootnotes(f))]
    .flatMap((f) => f.dataItems.map((d) => ({ ...d, footnote: f })))
    .map((r, idx) => ({ key: idx, ...r }));

  const names = [...new Set(all.map((d) => d.Name))]
  const mappings = [...new Set(all.map((d) => d.mapping))] as string[]

  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      filters: names.map((n) => ({ text: n, value: n })),
      onFilter: (value: any, record: any) => record.Name === value,

    },
    {
      title: "Year",
      dataIndex: "Year",
      key: "Year",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
      sorter: (a: any, b: any) =>
        coerceNumber(a.Amount) - coerceNumber(b.Amount),
      sortOrder: sortedInfo.columnKey === "Amount" ? sortedInfo.order : null,
    },
    {
      title: "mapping",
      dataIndex: "mapping",
      key: "mapping",
      sorter: (a: any, b: any) => a.mapping.localeCompare(b.mapping),
      sortOrder: sortedInfo.columnKey === "mapping" ? sortedInfo.order : null,
      filters: mappings.map((n) => ({ text: n, value: n })),
      onFilter: (value: any, record: any) => record.mapping === value,
    },
  ];

  const datasource = all;
  return (
    <Table
    
      dataSource={datasource}
      columns={columns}
      onChange={(pagination, filters, sorter) => {
        console.log("Various parameters", pagination, filters, sorter);
        setFilteredInfo(filters);
        setSortedInfo(sorter);
      }}
      pagination={{
        pageSize: 15, // Set the default pageSize to 20
        total: datasource.length, // Total number of items
      }}
      expandable={{
        expandedRowRender: (record) => (
          <>
            {record.parentItem 
            ? <p>parent: {record.parentItem.item.Description} {record.parentItem.item.Amount}</p>
            : <></>} 
            <p style={{ margin: 0 }}>{record.Description} </p>
            <p style={{ margin: 0 }}>{record.ReferenceText} </p>
            <hr />
            <p style={{ margin: 0, fontSize: "12px", color: "#333" }}>
              {record.footnote.reference} {record.footnote.content}
            </p>
          </>
        ),
        rowExpandable: (_record) => true,
      }}
      onRow={(record) => ({
        onClick: () => {context.hover(record.footnote.locations)},
        style: { cursor: 'pointer' },
      })}

    />
  );
}

export const TOTAL_FIELDS = {
  "Total Compensation": [
    "Salary",
    "Bonus",
    "Stock Awards",
    "Option Awards",
    "Non-Equity Incentive Plan (NEIP) Comp",
    "Change in Pension Value / NQDC Excess Interest",
    "All Other Compensation",
  ],
  "Total Retirement Benefit": [
    "Defined Contrib 1",
    "Defined Contrib 2",
    "ESOP Contrib",
    "Defined Benefit Contrib",
  ],
  "Total Travel and Entertainment Benefits": [
    "Commuting Expense",
    "Auto/Auto Insurance",
    "Club/Assn Dues",
    "Season Tickets",
    "Housing (Semi-Permanent)",
    "Business / Personal Travel",
    "Assignment / Relocation Expenses",
    "Other Travel / Entertainment",
  ],
  "Total General Services Benefits": [
    "Personal Security",
    "Charitable Awards",
    "Company Products",
    "Gifts / Awards",
    "Education Assistance",
    "Cell / Internet",
    "Other General Services",
  ],
  "Total Cash and Planning Benefits": [
    "Financial Planning",
    "Estate Planning",
    "Legal Assistance",
    "Tax Gross-Up",
    "Spending Allowance",
    "Vacation Cash-Out",
    "ESPP Match",
  ],
  "Total Executive Insurance Benefits": [
    "Life Insurance 1",
    "Life Insurance 2",
    "AD&D",
    "Disability",
    "Long-Term Care",
    "Liability",
    "Med / Dental / Vision",
    "Post-Retirement Supplemental",
    "Physicals",
    "Business Travel Accident",
    "Other",
    "Insurance Premium",
    "Commingled Value",
  ],
  "Commingled/Unspecified Total": [
    "Commingled 1",
    "Comm 1 Components",
    "Commingled 2",
    "Comm 2 Components",
    "Unspecified Perq",
    "Unspecified Components",
  ],
};

type TreeItem = {
  title: string,
  key: string,
  children: TreeItem[],
  isLeaf: boolean
}

export function DataItemTree(){
  const context = useContext(viewerContext);
  if (!context.current.doc) throw new Error("Expected doc");
  const viewer = useDocViewer(context.current.doc);
  const result = viewer.result;
  if (!result) {
    return <div>No result yet...</div>;
  }
  const dataItems = result.dataItems
  const dataItemId = (dataItem: DataItem) => `${dataItem.Name}-${dataItem.Year}-${dataItem.Amount}-${dataItem.Description}`
  const children = (dataItem: DataItem) => {
    return dataItems.filter(i => i.parentItem && dataItemId(i.parentItem.item) === dataItemId(dataItem))    
  }
  const withChildren = dataItems.filter( dataItem => children(dataItem).length > 0)

  const toTreeItem = (dataItem: DataItem):TreeItem => ({
    title: `${dataItem.Name} ${dataItem.Year} ${dataItem.mapping || ""} ${dataItem.Amount} `,
    key: dataItemId(dataItem),
    children: children(dataItem).map(c => toTreeItem(c)),
    isLeaf: children(dataItem).length === 0
  })

  const treeData = withChildren.map(r => toTreeItem(r))

  return <Tree treeData={treeData} />
}