import { useQuery } from "@tanstack/react-query";
import { Scope } from "./useApp";
import { post8kJob } from "../../api-clients/8k_502";
import { useState } from "react";

export function use8kProcessing(scope: Scope | undefined) {

  const [jobId, setJobId] = useState<string | undefined>(undefined)

  const submit = useQuery({
    queryKey: ["submit", scope],
    queryFn: async () => {
      if (!scope) return
      const job = await post8kJob(scope)
      setJobId(job.jobId)
    },
    enabled: Boolean(scope) && !jobId
  });

  // const pollStatus = useQuery({
  //   queryKey: ["pollStatus", jobId, dayjs().format("YYYY-MM-DD HH:mm:ss")],
  //   queryFn: async () => jobId ? poll8kJobStatus(jobId) : undefined,
  // });

  return {ready: submit.isFetched, jobId, resetJobId: () => setJobId(undefined)};
}
