import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { poll8kJobStatus } from "../../api-clients/8k_502";

interface JobStatus {  
  status: string;
  progress: number;
}

const JOB_STATUSES = ["pending", "in_progress", "ready", "error"];

export function usePollJobStatus(jobId: string) {
  const fetchJobStatus = useCallback(async () => {
    const response = await poll8kJobStatus(jobId);
    return response;
  }, [jobId]);

  const { data, isLoading, isError, refetch } = useQuery<JobStatus>(
    {
      queryKey: ['jobStatus', jobId],
      queryFn: fetchJobStatus,
      refetchInterval: (data) => {
        // Stop polling if the job is completed or if there's an error
        const status = data.state.data?.status || ""
        const looksLikeError = data.state.error || !JOB_STATUSES.includes(status) || status === "error"

        // poll every second
        return looksLikeError || status === "ready" ? false : 3000
        
      }
    }
  );

  const isCompleted = data?.status === 'ready';

  return { jobStatus: data, isLoading, isError, refetch, isCompleted };
}
