import * as ScrollArea from "@radix-ui/react-scroll-area";
import { useElementSize } from "@custom-react-hooks/use-element-size";
import { CloseOutlined, WarningOutlined } from "@ant-design/icons";
import { Button, Flex, Popconfirm, theme } from "antd";
import { useContext } from "react";
import { FilingSelect } from "../../components/FilingSelect";
import { chatContext } from "./useChatApp";
import { useDocStatus } from "./useDocStatus";

export function MenuPanel() {
  const context = useContext(chatContext);
  const { token } = theme.useToken();
  const [ref, size] = useElementSize<HTMLDivElement>();
  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        height: "100%",
        overflow: "hidden",
        backgroundColor: token.colorBgLayout,
      }}
    >
      <div
        style={{
          flex: 1,
          paddingLeft: 10,
          paddingRight: 0,
          paddingBottom: 10,
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            paddingTop: 14,
          }}
        >
          <div style={{ paddingRight: 15 }}>
            <div style={{ paddingLeft: 10, marginBottom: 5 }}>
              Select a document
            </div>
            <FilingSelect onOpen={context.open} />
          </div>
          <div
            style={{
              flex: 1,
              minHeight: 0,
              height: "100%",
              width: size.width - 25,
              overflow: "visible",
            }}
          >
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                margin: "20px 0 8px 0",
              }}
            >
              <div
                style={{
                  marginBottom: 5,
                  paddingLeft: 11.5,
                }}
              >
                Open chats
              </div>
              <div
                style={{
                  minHeight: 0,
                  maxWidth: "100%",
                  maxHeight: "100%",
                  height: "100%",
                  marginBottom: 40,
                  overflow: "hidden",
                  flex: 1,
                }}
              >
                {context.current.doc === undefined ? (
                  <div
                    style={{
                      textAlign: "center",
                      padding: "10px 20px",
                      backgroundColor: token.colorBgLayout,
                      color: token.colorTextTertiary,
                      borderRadius: 7,
                    }}
                  >
                    select a filing from the finder above
                  </div>
                ) : (
                  <ScrollArea.Root className="ScrollAreaRoot">
                    <ScrollArea.Viewport className="ScrollAreaViewport">
                      {context.list.map((chat_, i) =>
                        chat_.doc === undefined ? null : (
                          <ChatMenuItem
                            key={chat_.id}
                            {...chat_.doc}
                            width={size.width - 5}
                            onClick={() => context.open(chat_.doc!)}
                            isSelected={chat_.id === context.current.id}
                            isFirst={i === 0}
                            close={()=> context.close(chat_.doc!)
                          }
                          />
                        )
                      )}
                    </ScrollArea.Viewport>
                    <ScrollArea.Scrollbar
                      className="ScrollAreaScrollbar"
                      orientation="vertical"
                    >
                      <ScrollArea.Thumb className="ScrollAreaThumb" />
                    </ScrollArea.Scrollbar>
                  </ScrollArea.Root>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ChatMenuItem({
  ticker,
  form,
  year,
  onLoaded,
  onLoading,
  close,
  ...props
}: {
  title: string;
  ticker: string;
  form: string;
  year: number;
  width: number;
  isSelected: boolean;
  isFirst: boolean;
  onClick(): void;
  onLoaded?: () => void;
  onLoading?: () => void;
  close: () => void;
}){
  const status = useDocStatus({ ticker, form, year }, { onLoaded, onLoading });
  return (<FilingItem {...props} ticker={ticker} form={form} year={year} close={close} status={status} />)
}

export function FilingItem({
  ticker,
  form,
  year,
  close,
  status,
  ...props
}: {
  title: string;
  ticker: string;
  form: string;
  year: number;
  width: number;
  isSelected: boolean;
  isFirst: boolean;
  onClick(): void;
  onLoaded?: () => void;
  onLoading?: () => void;
  close: () => void;
  status: string
}) {
  const { token } = theme.useToken();
  return (
    <Button
      icon={status === "error" ? <WarningOutlined /> : undefined}
      loading={status === "loading"}
      disabled={status === "loading"}
      size="large"
      block
      type={props.isSelected ? "default" : "text"}
      onClick={() => {
        if (!props.isSelected) props.onClick();
      }}
      style={{
        width: props.width - 20,
        overflow: "hidden",
        boxShadow: props.isSelected ? token.boxShadowTertiary : undefined,
        color: status === "error" ? token.colorErrorText : undefined,
        cursor: status === "error" || props.isSelected ? "default" : "pointer",
        maxWidth: "100%",
        minWidth: 0,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 8,
        paddingRight: props.isSelected ? 7 : undefined,
      }}
    >
      <div
        style={{
          fontWeight: props.isSelected ? 600 : 400,
          textAlign: "left",
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textTransform: "uppercase",
          fontSize: "small",
          color:
            status === "error"
              ? token.colorErrorText
              : props.isSelected
              ? token.colorText
              : token.colorTextSecondary,
        }}
      >
        {ticker}
      </div>
      <Flex
        align="center"
        style={{ flex: 1, textAlign: "right" }}
        justify="flex-end"
        gap={4}
      >
        <Tag
          colors={[token.colorPrimary, `${token.colorPrimary}33`]}
          text={form}
          isSelected={props.isSelected}
          isError={status === "error"}
        />
        <Tag
          colors={[token.lime7, `${token.lime6}77`]}
          text={`${year}`}
          isSelected={props.isSelected}
          isError={status === "error"}
        />
        {props.isSelected && (
          <Popconfirm
            title="Close this document and chat?"
            placement="right"
            okText="Close"
            icon={null}
            okType="primary"
            okButtonProps={{ danger: true }}
            cancelButtonProps={{ type: "text" }}
            showCancel={false}
            onConfirm={() => {
              close();
            }}
          >
            <Button
              type="text"
              shape="circle"
              size="small"
              icon={<CloseOutlined />}
            />
          </Popconfirm>
        )}
      </Flex>
    </Button>
  );
}

function Tag(props: {
  isSelected: boolean;
  isError: boolean;
  text: string;
  colors: [string, string];
}) {
  const { token } = theme.useToken();
  return (
    <div
      style={{
        margin: 0,
        fontWeight: 600,
        textAlign: "center",
        textOverflow: "ellipsis",
        overflow: "hidden",
        fontSize: "small",
        borderRadius: 4,
        padding: "1px 5px",
        backgroundColor: props.isError
          ? token.colorErrorBorder
          : props.isSelected
          ? props.colors[0]
          : props.colors[1],
        color: props.isError
          ? token.colorTextLightSolid
          : props.isSelected
          ? token.colorTextLightSolid
          : token.colorTextTertiary,
      }}
    >
      {props.text}
    </div>
  );
}
