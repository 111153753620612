import { Empty, Flex, Table, theme } from "antd";
import { useContext, useState } from "react";
import { useDocViewer } from "./useDocViewer";
import { viewerContext } from "./useViewerApp";

export function ResultPanel() {
  const { token } = theme.useToken();
  const context = useContext(viewerContext);
  const hasDoc = Boolean(context.current.doc);
  return hasDoc ? (
      <>
        <h1>evaluation</h1>
        <ResultViewerPanel />
      </>
  ) : (
    <Flex
      align="center"
      justify="center"
      style={{
        height: "100%",
        backgroundColor: `${token.colorBgLayout}99`,
      }}
    >
      <Empty description="No filing selected" />
    </Flex>
  );
}

function ResultViewerPanel() {
  const { token } = theme.useToken();
  const context = useContext(viewerContext);
  if (!context.current.doc) throw new Error("Expected doc");

  const viewer = useDocViewer(context.current.doc);
  const [filteredInfo, setFilteredInfo] = useState({} as any);
  const [sortedInfo, setSortedInfo] = useState({} as any);

  const result = viewer.result;
  if (!result) {
    return <div>No result yet...</div>;
  }
  const all = result.evaluation.flat().map((r, idx) => ({ ...r, key: idx }));
  // organize as a tree structure.
  // group by name, then use TOTAL_FIELDS to assigne parent-child.
  const names = [...new Set(all.map((f) => f.officerName))];
  const datasource = names.flatMap((name) => {
    const officer = all.filter((r) => r.officerName === name);
    return Object.keys(TOTAL_FIELDS)
      .map((parentKey) => {
        const parent = officer.find((d) => d.column === parentKey);
        if (!parent) return null;
        const children = TOTAL_FIELDS[parentKey as keyof typeof TOTAL_FIELDS]
          .map((col) => officer.find((d) => d.column === col))
          .filter(Boolean);
        return { ...parent, children };
      })
      .filter(Boolean);
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "officerName",
      key: "officerName",
      filteredValue: filteredInfo.officerName || null,
      filters: names.map((n) => ({ text: n, value: n })),
      onFilter: (value: any, record: any) => record.officerName === value,
    },
    {
      title: "field",
      dataIndex: "column",
      key: "column",
      filters: [
        { text: "total", value: "total" },
        ...Object.keys(TOTAL_FIELDS).map((k) => ({ text: k, value: k })),
      ],
      filteredValue: filteredInfo.column || null,
      onFilter: (value: any, record: any) => {
        return (
          record.column
            .toLowerCase()
            .includes((value as string).toLowerCase()) ||
          (TOTAL_FIELDS[value as keyof typeof TOTAL_FIELDS] || []).includes(
            record.column
          )
        );
      },
    },
    {
      title: "output",
      dataIndex: "output",
      key: "output",
      sorter: (a: any, b: any) => Number(a.output) - Number(b.output),
      sortOrder: sortedInfo.columnKey === "output" ? sortedInfo.order : null,
    },
    {
      title: "truth",
      dataIndex: "truth",
      key: "truth",
      sorter: (a: any, b: any) => Number(a.truth) - Number(b.truth),
      sortOrder: sortedInfo.columnKey === "truth" ? sortedInfo.order : null,
    },
    {
      title: "result",
      dataIndex: "result",
      key: "result",
      filters: [
        { text: "match", value: "match" },
        { text: "no match", value: "no match" },
        { text: "no data", value: "no data" },
      ],
      filteredValue: filteredInfo.result || null,
      onFilter: (value: any, record: any) =>
        record.result === (value as string),
    },
  ];
  // const datasource = all
  return (
    <>
    <div
      style={{
        display: "flex",
        height: "100%",
        overflow: "hidden",
        backgroundColor: token.colorBgLayout,
      }}
    >
      <Table
        dataSource={datasource}
        columns={columns}
        onChange={(pagination, filters, sorter) => {
          console.log("Various parameters", pagination, filters, sorter);
          setFilteredInfo(filters);
          setSortedInfo(sorter);
        }}
        pagination={{
          pageSize: 15, // Set the default pageSize to 20
          total: datasource.length, // Total number of items
        }}
      />
    </div>
    </>
  );
}

const TOTAL_FIELDS = {
  "Total Compensation": [
    "Salary",
    "Bonus",
    "Stock Awards",
    "Option Awards",
    "Non-Equity Incentive Plan (NEIP) Comp",
    "Change in Pension Value / NQDC Excess Interest",
    "All Other Compensation",
  ],
  "Total Retirement Benefit": [
    "Defined Contrib 1",
    "Defined Contrib 2",
    "ESOP Contrib",
    "Defined Benefit Contrib",
  ],
  "Total Travel and Entertainment Benefits": [
    "Commuting Expense",
    "Auto/Auto Insurance",
    "Club/Assn Dues",
    "Season Tickets",
    "Housing (Semi-Permanent)",
    "Business / Personal Travel",
    "Assignment / Relocation Expenses",
    "Other Travel / Entertainment",
  ],
  "Total General Services Benefits": [
    "Personal Security",
    "Charitable Awards",
    "Company Products",
    "Gifts / Awards",
    "Education Assistance",
    "Cell / Internet",
    "Other General Services",
  ],
  "Total Cash and Planning Benefits": [
    "Financial Planning",
    "Estate Planning",
    "Legal Assistance",
    "Tax Gross-Up",
    "Spending Allowance",
    "Vacation Cash-Out",
    "ESPP Match",
    "Severence"
  ],
  "Total Executive Insurance Benefits": [
    "Life Insurance 1",
    "Life Insurance 2",
    "AD&D",
    "Disability",
    "Long-Term Care",
    "Liability",
    "Med / Dental / Vision",
    "Post-Retirement Supplemental",
    "Physicals",
    "Business Travel Accident",
    "Other",
    "Insurance Premium",
    "Commingled Value",
  ],
  "Commingled/Unspecified Total": [
    "Commingled 1",
    "Comm 1 Components",
    "Commingled 2",
    "Comm 2 Components",
    "Unspecified Perq",
    "Unspecified Components",
  ],
};
