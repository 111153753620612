import { createContext, useCallback, useState } from "react";
import { UseCase } from "./use-cases/types";


export type UseApp = ReturnType<typeof useApp>
export const appContext = createContext(undefined as unknown as UseApp)

export type Scope = {
    tickers: string[],
    since: string,
    until: string | undefined,
    requestedAtHour: string
}

export function useApp() {
    const [doc, setDoc] = useState<Doc>();

    const [highlights, setHighlights] = useState<{ startLocator: string, endLocator: string }[]>([])
    const [focused, setFocus] = useState<{locator:string} | undefined>(undefined)
    const [hovered, setHover] = useState<{ startLocator: string | undefined, endLocator: string | undefined }[]>([])

    const [useCase, setUseCase] = useState<UseCase>("STI")

    const open = useCallback((doc: Doc) => {
        setDoc(doc);
    }, []);


    return {
        doc,
        open,
        close: () => setDoc(undefined),
        setHighlights,
        highlights,
        focused,
        setFocus,
        hovered,
        setHover,
        useCase,
        setUseCase
    }
}

export type Doc = {
    title: string;
    year: number;
    ticker: string;
    form: string;
    accessionNumber: string;
  };
  