import Split from "react-split";
import { Flex, theme } from "antd";
import { PropsWithChildren, useContext, useState } from "react";
import { useDocumentTitle } from "@custom-react-hooks/use-document-title";
import { DocPanel } from "../DocPanel";
import { MenuPanel } from "../MenuPanel";
import { FilingSelect } from "../../../components/FilingSelect";
import { useViewerApp, viewerContext } from "../useViewerApp";
import { ResultPanel } from "../ResultPanel";
import { TracingPanel } from "../TracingPanel";

export function ViewerScreen() {
  const context = useViewerApp();
  const title = `${process.env.REACT_APP_PROJECT_NAME} ${
    context.doc
      ? ` - ${context.doc.ticker} ${context.doc.form}`
      : ""
  }`;
  useDocumentTitle(title);

  const hasDoc = context.doc;
  return (
    <viewerContext.Provider value={context}>
      {hasDoc ? <DevToolScreen /> : <StartViewerScreen />}
    </viewerContext.Provider>
  );
}

function StartViewerScreen() {
  const { token } = theme.useToken();
  const context = useContext(viewerContext);
  return (
    <Flex justify="center">
      <div
        style={{
          marginTop: 100,
          borderRadius: 15,
          padding: 15,
          paddingBottom: 25,
          backgroundColor: token.colorBgContainer,
          boxShadow: token.boxShadowTertiary,
        }}
      >
        <div style={{ marginBottom: 4, width: 420 }}>
          Start by selecting a document
        </div>
        <FilingSelect onOpen={context.open} />
      </div>
    </Flex>
  );
}

function DevToolScreen() {
  const [sizes, setSizes] = useState([0, 35,30,35]);
  return (
    <div
      style={{
        height: "100%",
        borderRadius: "inherit",
      }}
    >
      <Split
        sizes={sizes}
        minSize={232}
        expandToMin={true}
        gutterSize={10}
        gutterAlign="center"
        snapOffset={10}
        dragInterval={1}
        direction="horizontal"
        cursor="col-resize"
        onDragEnd={(newSizes) => setSizes(newSizes)}
        className="split"
      >
        <SplitPanel>
          <MenuPanel />
        </SplitPanel>
        <SplitPanel>
          <DocPanel />
        </SplitPanel>
        <SplitPanel>
          <ResultPanel />
        </SplitPanel>
        <SplitPanel>
          <TracingPanel />
        </SplitPanel>
      </Split>
    </div>
  );
}

function SplitPanel(props: PropsWithChildren) {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        overflow: "visible",
      }}
    >
      {props.children}
    </div>
  );
}
