import { useContext } from "react"
import { SOG } from "../../../../../chester/src/domains/chester/sog/zod"
import { appContext } from "../useApp"
import { Table } from "antd"
import { Located } from "./types";
import { RenderLocated } from "./common";


const figureLocations = (data: SOG[]) => {
    if (!data) return []
    return data.flatMap(d => [d.ownershipTarget, d.targetTimeFrame, ...d.withHoldingRules, ...d.qualifyingStock]
        .filter(d => d && "location" in d)
        .map(d => (d as Record<string, any>).location))
        .filter(Boolean) as Located["location"][]
}

const formatTimeFrame = (d: SOG["targetTimeFrame"]["value"]) => {
    return `${d.quantity} ${d.unit}`
}

const formatOwnershipTarget = (d: SOG["ownershipTarget"]["value"]) => {
    if ("items" in d) {
        const itemStr = d.items.map(i => `${i.name} ${i.quantity} ${i.unit}`).join(", ")
        return `${d.combinedWith} ${itemStr}`
    } else {
        return `${d.name} ${d.quantity} ${d.unit}`
    }
}

const SOGResults = ({ data }: { data: SOG[] }) => {
    const context = useContext(appContext)
    const { setFocus, setHover } = context

    const datasource = data.map((d, idx) => ({
        key: idx,
        ...d,
        applicableTo: {
            value: d.applicableTo && Object.keys(d.applicableTo).length > 0
                ? "officerName" in d.applicableTo
                    ? d.applicableTo.officerName
                    : d.applicableTo.roles.join(", ")
                : "",
        },        
        ownershipTarget: {
            value: formatOwnershipTarget(d.ownershipTarget.value),
            location: (d.ownershipTarget as Record<string, any>).location
        },
        targetTimeFrame: {
            value: formatTimeFrame(d.targetTimeFrame.value) ,
            location: (d.targetTimeFrame as Record<string, any>).location
        },
        
    }))

    const render = (value: Located) => RenderLocated({value, setFocus, setHover})

    const columns = ["applicableTo", "ownershipTarget", "targetTimeFrame"].map(title => ({
        title,
        dataIndex: title,
        key: title,
        render,
    }))

    return (
    <Table 
        dataSource={datasource} 
        columns={columns} 
        expandable={{
            expandedRowRender: (record) => <>
                <h4>qualifying stock</h4>
                <QualifyingStockTable record={record.qualifyingStock} />
                <h4>withholding rules</h4>
                <WithHoldingRulesTable record={record.withHoldingRules} />
            </>
        }}
    />)
}

const QualifyingStockTable = ({record}:{record: SOG["qualifyingStock"]}) => {
    const context = useContext(appContext)
    const { setFocus, setHover } = context
    const render = (value: Located) => <RenderLocated 
        value={value} 
        setFocus={setFocus} 
        setHover={setHover} />
    
    const columns = ["name", "shareKind", "qualifyingFraction", "vestingStatus"].map(title => ({
        title,
        dataIndex: title,
        key: title,
        render,
    }))
    const datasource = record.map(d => ({
        name: {
            value: d.value.name,
            location: (d as Record<string, any>).location
        },        
        shareKind: {
            value: d.value.shareKind,
            location: (d as Record<string, any>).location
        },
        qualifyingFraction: {
            value: d.value.qualifyingFraction,
            location: (d as Record<string, any>).location
        },
        vestingStatus: {
            value: d.value.vestingStatus,
            location: (d as Record<string, any>).location
        },
    }))

    return <Table dataSource={datasource} columns={columns} pagination={false} />
}

const WithHoldingRulesTable = ({record}:{record: SOG["withHoldingRules"]}) => {
    const context = useContext(appContext)
    const { setFocus, setHover } = context
    
    const render = (value: Located) => <RenderLocated 
        value={value} 
        setFocus={setFocus} 
        setHover={setHover} />
    
    const columns = ["from", "until", "percentage", "unit", "condition"].map(title => ({
        title,
        dataIndex: title,
        key: title,
        render,
    }))

    const datasource = record.map(d => ({
        from: {
            value: formatDuration(d.value.from),
            location: (d as Record<string, any>).location
        },        
        until: {
            value: formatDuration(d.value.until),
            location: (d as Record<string, any>).location
        },
        percentage: {
            value: "rule" in d.value ? d.value.rule : d.value.percentage,
            location: (d as Record<string, any>).location
        },
        unit: {
            value: "unit" in d.value ? d.value.unit : "",
            location: (d as Record<string, any>).location
        },
        condition: {
            value: d.value.condition,
            location: (d as Record<string, any>).location
        },
    }))

    return <Table dataSource={datasource} columns={columns} pagination={false} />
}

const formatDuration = (d: SOG["withHoldingRules"][number]["value"]["until"]) => {
    if (!d) return ""
    if (typeof d == "string"){
        return d
    } else if ("quantity" in d) {
        return `${d.quantity} ${d.unit}`
    } else {
        return ""
    }
}

    
 
export const SOGUseCase = {
    DataView: SOGResults,
    figureLocations
}