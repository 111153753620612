import { Page } from "../../components/Page";
import { appContext, useApp } from "./useApp";
import { Flex, theme } from "antd";
import { FilingSelect } from "../../components/FilingSelect";
import { useContext } from "react";
import { ExplorerScreen } from "./screens/view";
export function DevExplorerPage() {

  const context = useApp()
  const hasDoc = context.doc!!
  return (
    <Page>
      <appContext.Provider value={context}>
        {hasDoc ? <ExplorerScreen /> : <StartViewerScreen />}
      </appContext.Provider>
    </Page>
  );
}


function StartViewerScreen() {
  const { token } = theme.useToken();
  const context = useContext(appContext);
  return (
    <Flex justify="center">
      <div
        style={{
          marginTop: 100,
          borderRadius: 15,
          padding: 15,
          paddingBottom: 25,
          backgroundColor: token.colorBgContainer,
          boxShadow: token.boxShadowTertiary,
        }}
      >
        <div style={{ marginBottom: 4, width: 420 }}>
          Start by selecting a document
        </div>
        <FilingSelect onOpen={context.open} />
      </div>
    </Flex>
  );
}
