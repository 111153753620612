import {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  cloneElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AutoComplete, Button, Input, theme } from "antd";
import { useCiksQuery } from "../hooks/useCiks";

export function FilingSelect(props: {
  disabled?: boolean;
  onOpen(doc: {
    title: string;
    form: string;
    ticker: string;
    year: number;
  }): void;
}) {
  const [forms, setForms] = useState(["DEF 14A", "10-K", "10-Q", "4"]);
  const years = useMemo(() => ["2024"], []);
  const { token } = theme.useToken();
  const [filter, setFilter] = useState("");
  const ciksQuery = useCiksQuery();

  useEffect(() => {
    if (forms.length === 0) {
      setForms(["DEF 14A", "10-K", "10-Q", "4"]);
    }
  }, [forms.length]);

  const filings = useMemo(() => {
    const words = filter.toLowerCase().trim().split(" ").filter(Boolean);
    const options: {
      label: ReactNode;
      value: string;
      title: string;
      ticker: string;
      year: string;
      form: string;
      strength: number;
    }[] = [];
    for (const { ticker, title } of ciksQuery.data || []) {
      for (const year of years) {
        for (const form of forms) {
          const value = `${ticker} ${year} ${form} ${title}`.toLowerCase();
          if (words.length === 0 || words.every((word) => value.includes(word)))
            options.push({
              label: (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: 5,
                    fontWeight: 600,
                    color: token.colorTextTertiary,
                    fontSize: 12,
                  }}
                >
                  <div style={{ fontSize: 13, color: token.colorText }}>
                    {ticker}
                  </div>
                  <div
                    style={{
                      backgroundColor: `${token.colorPrimary}33`,
                      padding: "1px 5px",
                      borderRadius: 3,
                    }}
                  >
                    {form}
                  </div>
                  <div
                    style={{
                      backgroundColor: `${token.lime6}77`,
                      padding: "1px 5px",
                      borderRadius: 3,
                    }}
                  >
                    {year}
                  </div>
                  <div
                    style={{
                      minWidth: 0,
                      maxWidth: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      fontSize: 11,
                      color: token.colorTextSecondary,
                    }}
                  >
                    ({title})
                  </div>
                </div>
              ),
              value,
              title,
              ticker,
              year,
              form,
              strength: words.includes(ticker.toLowerCase()) ? 1 : 0,
            });
        }
      }
    }
    options.sort((a, b) => {
      if (a.strength !== b.strength) return b.strength - a.strength;
      if (a.ticker.length !== b.ticker.length)
        return a.ticker.length - b.ticker.length;
      return a.ticker.localeCompare(b.ticker);
    });
    return options;
  }, [
    forms,
    years,
    filter,
    ciksQuery.data,
    token.colorPrimary,
    token.colorText,
    token.colorTextSecondary,
    token.colorTextTertiary,
    token.lime6,
  ]);

  return (
    <AutoComplete
      disabled={props.disabled}
      placeholder="Enter ticker"
      allowClear
      onSearch={(search) => setFilter(search)}
      onSelect={(search, { title, form, year, ticker }) => {
        if (!search || props.disabled) return;
        props.onOpen({ title, form, year: Number(year), ticker });
        setFilter("");
      }}
      value={filter}
      style={{ width: "100%" }}
      options={filings}
      dropdownRender={(menu) => (
        <div>
          <div
            style={{
              maxHeight: `calc(100vh - 200px)`,
              overflow: "auto",
            }}
          >
            <div
              style={{
                padding: "4px 10px 10px",
                borderBottom: `solid 1px ${token.colorBorder}`,
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: 1,
                }}
              >
                <TagButton
                  first
                  value={"DEF 14A"}
                  values={forms}
                  setValues={setForms}
                />
                <TagButton value={"10-K"} values={forms} setValues={setForms} />
                <TagButton value={"10-Q"} values={forms} setValues={setForms} />
                <TagButton
                  last
                  value={"4"}
                  values={forms}
                  setValues={setForms}
                />
              </div>
            </div>
            <div style={{ padding: 2 }}>
              {cloneElement(menu as ReactElement, {
                style: { boxShadow: "none" },
              })}
            </div>
          </div>
        </div>
      )}
    >
      <Input.Search disabled={props.disabled} size="large" />
    </AutoComplete>
  );
}

function TagButton({
  value,
  values,
  setValues,
  disabled,
  first,
  last,
}: {
  value: string;
  values: string[];
  setValues: Dispatch<SetStateAction<string[]>>;
  disabled?: boolean;
  first?: boolean;
  last?: boolean;
}) {
  const checked = values.includes(value);
  return (
    <Button
      disabled={disabled}
      size="small"
      value={value}
      onClick={() => {
        if (checked) setValues(values.filter((val) => val !== value));
        else setValues([...values, value]);
      }}
      type={checked ? "primary" : "default"}
      style={{
        padding: "2px 5px 1px",
        borderRadius: first ? "4px 0 0 4px" : last ? "0 4px 4px 0" : 0,
      }}
    >
      {value}
    </Button>
  );
}
