import { Scope } from "../pages/dev-8k-502-viewer/useApp";
import { get, getBlob, post } from "./lib";


export function process8k502(query: {
    ticker: string;
    accessionNumber: string;
  }): Promise<PositionChange[]> {
    return post(`/api/8k502/process.json`, query);
  }
  
  type DocLocation = {
    startLocator?: string,
    endLocator?: string
  }
  
  export type CompensationItem = {
    Amount: number,
    Unit: string,
    Description: string,
    Kind: string,
    ReferenceText: string
    ReferenceLocation?: DocLocation
    Period?: string
  }
  
  
  export type PositionChange = {
    Name: string,
    Positions: string[],
    StandardJobTitle: string,
    EffectiveDate: string,
    Change: "appointment" | "departure"
    ReferenceText: string
    ReferenceLocation?: DocLocation
    CompensationItems: CompensationItem[]
  }
  

export function post8kJob(scope:Scope): Promise<any> {
  return post(`/api/8k502/processBatch.json`, scope)
}

export function poll8kJobStatus(jobId:string): Promise<any> {
  return get(`/api/8k502/status.json`, {jobId})
}

  export function get8kJobResults(jobId:string): Promise<any> {
    return getBlob(`/api/8k502/results.xlsx`, {jobId})
  }

  export function get8kJobSummary(jobId:string): Promise<{ticker: string, positionChanges: number}[]> {
    return get(`/api/8k502/summary.json`, {jobId})
  }