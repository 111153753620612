import { useQuery } from "@tanstack/react-query";
import { processUseCase } from "../../api-clients/def14a";
import { UseCase } from "./use-cases/types";


export function useResults(
  useCase: UseCase,
  doc: {
    ticker: string;
    year: number;
    form: string;
    accessionNumber: string;
  } | undefined) {

  const query = useQuery({
    queryKey: ["useCase", useCase, doc],
    queryFn: async () => {
      return processUseCase(useCase, doc!)
    }
  });


  return { ready: query.isFetched, data: query.data };
}
