import { useContext, useEffect, useState } from "react";
import { viewerContext } from "./useViewerApp";

import { Tree } from 'antd';
import type { TreeProps } from 'antd';
import { DocMap } from "../../api-clients/def14a";



export function DocmapComponent(props: { sections: DocMap['sections'], docMap: DocMap }) {
    const context = useContext(viewerContext);
    const { sections, docMap } = props
    const {setFocus, highlight} = context

    const [categories, setCategories] = useState<string[]>([])
    const [clicked, setClicked] = useState<string | undefined>(undefined)



    useEffect(() => {
        if (sections && categories.length > 0) {
            highlight([])
            const selected = categories.map(c => sections[c])
            const locations = selected.flatMap(section => section.flatMap(s => s.locators)).map(l => ({ locator: l })).flat()
            setFocus(locations.at(0))
            highlight(locations)
        }
    }, [sections, categories, setFocus, highlight])

    useEffect(() => {
        if (sections && clicked) {
            setFocus({ locator: clicked })
        }
    }, [sections, clicked, setFocus])

    if (!docMap || !sections) return <div>No docmap yet...</div>

    const treeData = Object.keys(sections).map((s, idx) => ({
        title: s,
        key: s,
        children: sections[s].map((l, sIDx) => ({
            title: l.title + " " + pageStr(l.pages),
            key: `_sec_${idx}_${sIDx}`,
            isLeaf: true
        }))
    }))
    const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
        console.log('selected', selectedKeys, info);
        // figure the selected categories
        // and the 
        const cats: string[] = []
        for (const key of selectedKeys) {
            const { category } = interpretKey(key.toString(), sections)
            cats.push(category)
        }
        setCategories(cats)
    };
    const onClick: TreeProps['onClick'] = (_event, node) => {
        const { category, section } = interpretKey(node.key.toString(), sections)
        if (section) {
            setClicked(section.locators.at(0))
        } else {
            setClicked(sections[category].at(0)?.locators.at(0))
        }
    };

    return (<Tree
        treeData={treeData}
        onSelect={onSelect}
        onClick={onClick}
    />)
}

const interpretKey = (key: string, sections: DocMap['sections']) => {
    if (key.toString().startsWith("_sec_")) {
        const catId = key.toString().split("_")[2]
        const sectionId = key.toString().split("_")[3]
        const category = Object.keys(sections).at(Number(catId))!
        return {
            category,
            section: sections[category].at(Number(sectionId))!
        }
    } else {
        return {
            category: key,
            section: undefined
        }
    }
}

export const pageStr = (pages: string[]) => {
    if (pages.length === 1) {
        return `[${pages[0]}]`
    } else if (pages.length > 1) {
        return `[${pages[0]} - ${pages.at(-1)}]`
    } else {
        return ""
    }
}