import { createContext, useCallback, useState } from "react";

export type UseViewerApp = ReturnType<typeof useViewerApp>;

export const viewerContext = createContext(
  undefined as unknown as UseViewerApp
);

export function useViewerApp() {
  const [doc, setDoc] = useState<Doc>();

  const [highlightSet, setHighlightSet] = useState<{locator: string}[]>([])

  const addHighlights = (locations: {locator:string}[]) => {
    setHighlightSet(prev => {
      const seen = new Set(prev.map(l => l.locator))
      const newItems = locations.filter(l => !seen.has(l.locator))    
      console.log(`adding ${newItems.length} highlights to higlightSet`)
      if (newItems.length === 0) return prev
      return [...prev, ...newItems]
    })
  }

  const removeHighlights = (locations: {locator:string}[]) => {
    const toRemove = new Set(locations.map(l => l.locator))
    console.log(`removing ${toRemove} highlights from higlightSet`)
    setHighlightSet(prev => prev.filter(l => !toRemove.has(l.locator)))
  }

  const highlight = useCallback((locations: {locator:string}[]) => {
    setHighlightSet(prev => {
      const seen = new Set(prev.map(l => l.locator))
      const newItems = locations.filter(l => !seen.has(l.locator))    
      if (newItems.length === 0 && locations.length === seen.size) return prev

      console.log(`adding ${newItems.length} highlights to higlightSet`)
      return [...newItems]
    })
  }, [])

  const [hovered, setHover] = useState<{locator:string}[]>([])
  const [focused, setFocus] = useState<{locator:string} | undefined>(undefined)


  const hover = useCallback((locations: {locator:string}[]) => {
    setHover(locations)
  }, [])

  const open = useCallback((doc: Doc) => {
    setDoc(doc);
  }, []);
  return {
    open,
    current: { doc },
    doc,
    highlight,
    highlighted: highlightSet,
    addHighlights,
    removeHighlights,
    hover,
    hovered:hovered,
    setFocus,
    focused,
  };
}

export type Doc = {
  title: string;
  year: number;
  ticker: string;
  form: string;
};
