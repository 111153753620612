import * as ScrollArea from "@radix-ui/react-scroll-area";
import { useElementSize } from "@custom-react-hooks/use-element-size";
import { useContext } from "react";
import { theme } from "antd";
import { FilingSelect } from "../../components/FilingSelect";
import { viewerContext } from "./useViewerApp";
import { DocmapExec } from "./DocmapExec";
import { DocsmoothmapPanel } from "./DocsmoothmapPanel";

export function MenuPanel() {
  const context = useContext(viewerContext);
  const { token } = theme.useToken();
  const [ref, size] = useElementSize<HTMLDivElement>();
  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        height: "100%",
        overflow: "hidden",
        backgroundColor: token.colorBgLayout,
      }}
    >
      <div
        style={{
          flex: 1,
          paddingLeft: 10,
          paddingRight: 0,
          paddingBottom: 10,
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            paddingTop: 14,
          }}
        >
          <div style={{ paddingRight: 15 }}>
            <div style={{ paddingLeft: 10, marginBottom: 5 }}>
              Select a document
            </div>
            <FilingSelect onOpen={context.open} />
          </div>
          <div
            style={{
              flex: 1,
              minHeight: 0,
              height: "100%",
              width: size.width - 25,
              overflow: "visible",
            }}
          >
            <div
              style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                margin: "20px 0 8px 0",
              }}
            >              
              <div
                style={{
                  minHeight: 0,
                  maxWidth: "100%",
                  maxHeight: "100%",
                  height: "100%",
                  marginBottom: 40,
                  overflow: "hidden",
                  flex: 1,
                }}
              >
                {context.doc === undefined ? (
                  <div
                    style={{
                      textAlign: "center",
                      padding: "10px 20px",
                      backgroundColor: token.colorBgLayout,
                      color: token.colorTextTertiary,
                      borderRadius: 7,
                    }}
                  >
                    select a filing from the finder above
                  </div>
                ) : (
                  <ScrollArea.Root className="ScrollAreaRoot">
                          <ScrollArea.Viewport className="ScrollAreaViewport">
                            {/* Categorized Content:
                            <DocmapPanel />
                            <br/> */}
                            Denoised categories:
                            <DocsmoothmapPanel />
                            <br/>
                            exec comp: CD&A principles // compensation datapoints
                            <DocmapExec />

                          </ScrollArea.Viewport>
                    <ScrollArea.Scrollbar
                      className="ScrollAreaScrollbar"
                      orientation="vertical"
                    >
                      <ScrollArea.Thumb className="ScrollAreaThumb" />
                    </ScrollArea.Scrollbar>
                  </ScrollArea.Root>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              marginTop: 12,
              display: "inline-block",
              width: size.width - 20,
              textAlign: "right",
              overflow: "hidden",
            }}
          >
            {/* <PromptSelect
              onChange={(prompt) => context.setPromptId(prompt.id)}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

