const root =
  process.env.NODE_ENV === "development"
    ? `http://localhost:8081`
    : "https://mdg.machineandpartners.com";

export async function post(path: string, body = {}) {
  const url = `${root}${path}`;
  const authorization = `Bearer ${localStorage.getItem("clerk-auth-token")}`;
  return fetch(url, {
    method: "POST",
    headers: { authorization, "Content-Type": "application/json" },
    body: JSON.stringify(body),
  }).then((resp) => resp.json());
}

export async function get(path: string, query = {}) {
  const url = new URL(`${root}${path}`);
  Object.entries(query).forEach(([key, value]) =>
    url.searchParams.append(key, `${value}`)
  );
  const authorization = `Bearer ${localStorage.getItem("clerk-auth-token")}`;
  return fetch(url, {
    headers: { authorization, "Content-Type": "application/json" },
  }).then((resp) => resp.json());
}


export async function getBlob(path: string, query = {}, contentType= "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
  const url = new URL(`${root}${path}`);
  Object.entries(query).forEach(([key, value]) =>
    url.searchParams.append(key, `${value}`)
  );
  const authorization = `Bearer ${localStorage.getItem("clerk-auth-token")}`;
  return fetch(url, {
    headers: { authorization, "Content-Type": contentType },
  }).then((resp) => resp.blob());
}