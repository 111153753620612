import { createContext, useState } from "react";
import dayjs from "dayjs";


export type UseDownload = ReturnType<typeof useDownload>
export const downloadContext = createContext(undefined as unknown as UseDownload)

export type Scope = {
    tickers: string[],
    since: string,
    until: string | undefined,
    requestedAtHour: string
}

export function useDownload() {
    const [scope, setScope] = useState<Scope | undefined>(undefined)    

    const submitTickers = (payload: {tickers: string, since: string}) => {
        const tickers = payload.tickers.split(",").map(t => t.trim())

        const requestedAtHour = dayjs().format("YYYY-MM-DDTHH")
        setScope({tickers, since: payload.since, until: undefined, requestedAtHour})
    }

    return {
        submitTickers,
        scope,
        resetScope: () => setScope(undefined),
    }
}
