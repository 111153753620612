import { Alert, Button, DatePicker, Flex, Form, FormProps, Input, List, Progress, theme } from "antd";
import { useContext } from "react";
import { useDocumentTitle } from "@custom-react-hooks/use-document-title";
import { use8kProcessing } from "../use8kProcessing";
import { downloadContext, useDownload } from "../useDownload";
import { usePollJobStatus } from "../usePollJobStatus";
import { get8kJobResults, get8kJobSummary } from "../../../api-clients/8k_502";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
dayjs.extend(quarterOfYear)
export function InputScreen() {
    const context = useDownload();
    const title = `${process.env.REACT_APP_PROJECT_NAME}`;
    useDocumentTitle(title);

    return (
        <downloadContext.Provider value={context}>
            {<StartViewerScreen />}
        </downloadContext.Provider>
    );
}

function StartViewerScreen() {
    const { token } = theme.useToken();
    return (
        <Flex justify="center">
            <div
                style={{
                    marginTop: 100,
                    borderRadius: 15,
                    padding: 15,
                    paddingBottom: 25,
                    backgroundColor: token.colorBgContainer,
                    boxShadow: token.boxShadowTertiary,
                }}
            >
                <div style={{ marginBottom: 4, width: 420 }}>
                    Enter a comma delimited list of tickers
                </div>
                <FilingSelect />
            </div>
        </Flex>
    );
}


const onFinishFailed: FormProps<{ tickers: string }>['onFinishFailed'] = (errorInfo) => {
    console.log('Failed:', errorInfo);
};

function FilingSelect() {

    const context = useContext(downloadContext)
    const { jobId, resetJobId } = use8kProcessing(context.scope)
    const [form] = Form.useForm();
    console.log("jobId", jobId)

    // 6 quarters ago, beginning of the quarter
    const defaultSince = dayjs().subtract(6, 'quarters').startOf('quarter');

    console.log(form.getFieldsValue())

    return (<Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 1000, minWidth: 420 }}
        initialValues={{ remember: true }}
        onFinish={(values: { tickers: string, since: Date }) => {
            context.submitTickers({ tickers: values.tickers, since: (values.since || defaultSince).toISOString() })
        }}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
    >
        <Form.Item<{ tickers: string }>
            name="tickers"
            rules={[{ required: true }]}
        >
            <Input
                style={{ width: 420 }}
                placeholder="e.g. AAPL, PG, ..."
                disabled={!!jobId}
            />
        </Form.Item>        
        <div style={{ marginBottom: 4, width: 420 }}>
                Obtain a spreadsheet of 8k-502 executive position changes
        </div>
        <Form.Item<{ since: dayjs.Dayjs }>
            name="since"
            label="since"
            labelAlign="left"
            wrapperCol={{ span: 16 }}
            style={{ width: 200 }}
        >
            <DatePicker format="YYYY-MM-DD" defaultValue={defaultSince} />
        </Form.Item>

        {!jobId && <Form.Item wrapperCol={{ span: 16 }}>
            <Button 
                type="primary" 
                htmlType="submit" 
                // disabled={form.getFieldValue("tickers") === ""}
            >
                Submit
            </Button>
        </Form.Item>}

        {jobId && (
            <Flex gap={10}>
                <JobResult jobId={jobId} />
                <Button type="default" onClick={() => {
                    context.resetScope()
                    resetJobId()
                }}>
                    Reset
                </Button>
            </Flex>
        )}

    </Form>
    )

}

<div style={{ marginBottom: 4, width: 420 }}>
    Enter a comma delimited list of tickers
</div>
function JobResult(props: { jobId: string }) {
    const { token } = theme.useToken()
    const { jobId } = props
    const { jobStatus, isCompleted, isError } = usePollJobStatus(jobId)

    const download = useQuery({
        queryKey: ["jobResults", jobId, isCompleted],
        queryFn: () => get8kJobResults(jobId),
        enabled: isCompleted
    })

    const progress = Math.floor((jobStatus?.progress || 0) * 100);

    return isCompleted && download.isSuccess
        ?
        <div>
            <Button type="primary" onClick={() => {
                downloadResults({ data: download.data })
            }}>
                Download Results
            </Button>
            <Summary jobId={jobId} />
        </div>
        : isError 
            ? <Alert type="error" message="An error occurred. Reset and try again"/>
            : <>
                <div>processing</div>
                <Progress strokeColor={token.colorPrimary} percent={progress} />
              </>
}

function Summary(props: { jobId: string }) {
    const { jobId } = props
    const { data } = useQuery({
        queryKey: ["jobSummary", jobId],
        queryFn: () => get8kJobSummary(jobId),
    })

    return (
    <>
        <div style={{marginTop: 12}}>Extracted:</div>
        <List style={{width: 200}}>
            {data?.map((d) => (
                <List.Item>
                    <List.Item.Meta
                        description={`${d.ticker}  ${d.positionChanges} position change(s)`}
                    />
                </List.Item>
            ))}
        </List>
    </>
    )
}

function downloadResults(props: { data: Blob }) {
    const { data } = props
    if (!data) return;
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', '8k_502_entries.xlsx');
    document.body.appendChild(link);
    link.click();
    link.remove();
    setTimeout(() => {
        window.URL.revokeObjectURL(url);
    }, 1000);
}