import createDOMPurify from "dompurify";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getFiling, processdef14a } from "../../api-clients";

export function useDocViewer(doc: {
  ticker: string;
  form: string;
  year: number;
} | undefined) {
  const query = useQuery({
    queryKey: ["filing", doc],
    queryFn: async () => getFiling(doc!),
  });

  const locateQuery = useQuery({
    queryKey: ["process", doc],
    queryFn: async () => doc ? processdef14a(doc) : undefined,
  });

  const [DOMPurify] = useState(createDOMPurify(window));
  const safe = DOMPurify.sanitize(query.data?.html || "");
  return { html: safe, ready: query.isFetched, result: locateQuery.data };
}
