import * as ScrollArea from "@radix-ui/react-scroll-area";
import { LoadingOutlined } from "@ant-design/icons";
import { Empty, Flex, Input, theme } from "antd";
import { useContext, useEffect } from "react";
// import { useDocSearch } from "./useDocSearch";
import { useDocViewer } from "./useDocViewer";
import { viewerContext } from "./useViewerApp";

export function DocPanel() {
  const { token } = theme.useToken();
  const context = useContext(viewerContext);
  const hasDoc = Boolean(context.current.doc);
  return hasDoc ? (
    <DocViewerPanel />
  ) : (
    <Flex
      align="center"
      justify="center"
      style={{
        height: "100%",
        backgroundColor: `${token.colorBgLayout}99`,
      }}
    >
      <Empty description="No filing selected" />
    </Flex>
  );
}

const locateElem = (location: string) => {
  const $el = document.querySelector("#document-html"); // your root
  if (!$el) return
  if (location.includes("_")){
    const [tag, idx] = location.split("_")
    if (Number(idx) === 0) return
    const elems = $el.querySelectorAll(tag)
    return elems[Number(idx) - 1]
  } else if (location.startsWith("T")){
    const idx = Number(location.slice(1)) - 1 // locator is 1-indexed
    const tables = $el.querySelectorAll('table')
    return tables[idx]
  } 
}

const highlight = (location: string | undefined) => {
  if (!location) return
  const elem = locateElem(location)
  if (elem){
    // check if node is HTMLElement
    if (elem instanceof HTMLElement) {
      elem.classList.add("highlight");
    } else if (elem instanceof Text) {

      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(elem);
      if (selection){
        // selection.removeAllRanges();
        selection.addRange(range);
      }
      
    }
  }
}

const frame = (location: string | undefined) => {
  if (!location) return
  const elem = locateElem(location)
  if (elem){
    // check if node is HTMLElement
    if (elem instanceof HTMLElement) {
      elem.classList.add("hoverborder");
    } else if (elem instanceof Text) {

      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(elem);
      if (selection){
        // selection.removeAllRanges();
        selection.addRange(range);
      }
      
    }

  }
}



const show = (location: string| undefined) => {
  if (!location) return
  const elem = locateElem(location)
  if (elem){
    if (elem instanceof HTMLElement){
      elem.scrollIntoView({block: "center"})
    }
  }
}

function DocViewerPanel() {
  const { token } = theme.useToken();
  const context = useContext(viewerContext);
  if (!context.current.doc) throw new Error("Expected doc");

  const viewer = useDocViewer(context.current.doc);

  useEffect(() => {
    const $el = document.querySelector("#document-html"); // your root
    if (!$el) return;
    console.log('removing all highlights')
    
    $el
      .querySelectorAll(".highlight")
      .forEach((el) => el.classList.remove("highlight"));

      const locations = context.highlighted
      console.log(`highlighting ${locations?.length} locations`)      
    for (const location of locations || []){
      highlight(location.locator)
    }
    // show(locations.at(0)?.locator)

  }, [viewer.ready, context.highlighted]);


  useEffect(() => {
    const $el = document.querySelector("#document-html"); // your root
    if (!$el) return;
    $el
    .querySelectorAll(".hoverborder")
    .forEach((el) => el.classList.remove("hoverborder"));
    const locations = context.hovered
    if (!locations || locations.length === 0) return
    for (const location of locations){
      frame(location.locator)
    }
    show(locations.at(0)!.locator)

  }, [viewer.ready, context.hovered]);

  useEffect(() => {
    if (context.focused){
      show(context.focused.locator)
    }
  },[viewer.ready, context.focused])


  if (!viewer.ready)
    return (
      <Flex justify="center" align="center" style={{ height: "100%" }}>
        <LoadingOutlined />
      </Flex>
    );

  return (
    <Flex
      vertical
      style={{
        height: "100%",
        border: `solid 1px ${token.colorBgContainer}`,
        borderRadius: 15,
        boxShadow: token.boxShadowTertiary,
      }}
    >
      <div
        style={{
          backgroundColor: token.colorBgContainer,
          borderRadius: "15px 15px 0 0",
          padding: 10,
        }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            // const input = `${
            //   (document.querySelector(`#document-search`) as HTMLInputElement)
            //     .value
            // }`.trim();
            // search(input);
          }}
        >
          <Input.Search id="document-search" />
        </form>
      </div>
      <div
        style={{
          overflow: "hidden",
          height: "100%",
          backgroundColor: token.colorBgContainer,
          borderRadius: "0 0 15px 15px",
        }}
      >
        <ScrollArea.Root className="ScrollAreaRoot">
          <ScrollArea.Viewport className="ScrollAreaViewport">
            <div id="document-html"
              style={{
                padding: 20,
              }}
              dangerouslySetInnerHTML={{ __html: viewer.html }}
            />
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar
            className="ScrollAreaScrollbar"
            orientation="vertical"
          >
            <ScrollArea.Thumb className="ScrollAreaThumb" />
          </ScrollArea.Scrollbar>
          <ScrollArea.Scrollbar
            className="ScrollAreaScrollbar"
            orientation="horizontal"
          >
            <ScrollArea.Thumb className="ScrollAreaThumb" />
          </ScrollArea.Scrollbar>
        </ScrollArea.Root>
      </div>
    </Flex>
  );
}
