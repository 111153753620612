import Split from "react-split";
import { PropsWithChildren, useState } from "react";
import { ChatPanel } from "../ChatPanel";
import { DocPanel } from "../DocPanel";
import { MenuPanel } from "../MenuPanel";

export function ChatReadyScreen() {
  const [sizes, setSizes] = useState([0, 50, 50]);
  return (
    <div
      style={{
        height: "100%",
        borderRadius: "inherit",
      }}
    >
      <Split
        sizes={sizes}
        minSize={232}
        expandToMin={true}
        gutterSize={10}
        gutterAlign="center"
        snapOffset={10}
        dragInterval={1}
        direction="horizontal"
        cursor="col-resize"
        onDragEnd={(newSizes) => setSizes(newSizes)}
        className="split"
      >
        <SplitPanel>
          <MenuPanel />
        </SplitPanel>
        <SplitPanel>
          <ChatPanel />
        </SplitPanel>
        <SplitPanel>
          <DocPanel />
        </SplitPanel>
      </Split>
    </div>
  );
}

function SplitPanel(props: PropsWithChildren) {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        overflow: "visible",
      }}
    >
      {props.children}
    </div>
  );
}
