import { Empty, Flex, theme } from "antd";
import { useContext } from "react";
import { useDocViewer } from "./useDocViewer";
import { viewerContext } from "./useViewerApp";

import { DocmapComponent } from "./DocmapComponent";


export function DocmapExec() {
    const { token } = theme.useToken();
    const context = useContext(viewerContext);
    const viewer = useDocViewer(context.current.doc);
    const docMap = viewer.result?.docMap
    const sections = docMap?.execSections

    return docMap && sections ? (
        <DocmapComponent
            sections={sections}
            docMap={docMap}
        />
    ) : (
        <Flex
            align="center"
            justify="center"
            style={{
                height: "100%",
                backgroundColor: `${token.colorBgLayout}99`,
            }}
        >
            <Empty description="No filing selected" />
        </Flex>
    );

}

export const pageStr = (pages: string[]) => {
    if (pages.length === 1) {
        return `[${pages[0]}]`
    } else if (pages.length > 1) {
        return `[${pages[0]} - ${pages.at(-1)}]`
    } else {
        return ""
    }
}