import Split from "react-split";
import { PropsWithChildren, useState, useContext } from "react";
import { useDocumentTitle } from "@custom-react-hooks/use-document-title";
import { appContext } from "../useApp";
import { DocPanel } from "../DocPanel";
import { ResultPanel } from "../ResultPanel";
import { UseCasePanel } from "../UseCasePanel";
export function ExplorerScreen() {
  const context = useContext(appContext);
  const title = `${process.env.REACT_APP_PROJECT_NAME} ${
    context.doc
      ? ` - ${context.doc.ticker} ${context.doc.accessionNumber}`
      : ""
  }`;
  useDocumentTitle(title);

  const hasDoc = context.doc;
  return (
    <appContext.Provider value={context}>
      {hasDoc ? <DevToolScreen /> : "no doc - pass in accession number and ticker"}
    </appContext.Provider>
  );
}

function DevToolScreen() {
  const [sizes, setSizes] = useState([0,50,50]);
  return (
    <div
      style={{
        height: "100%",
        borderRadius: "inherit",
      }}
    >
      <Split
        sizes={sizes}
        minSize={232}
        expandToMin={true}
        gutterSize={10}
        gutterAlign="center"
        snapOffset={10}
        dragInterval={1}
        direction="horizontal"
        cursor="col-resize"
        onDragEnd={(newSizes) => setSizes(newSizes)}
        className="split"
      >
        <SplitPanel>
          <UseCasePanel />
        </SplitPanel>
        <SplitPanel>
          <ResultPanel />
        </SplitPanel>
        <SplitPanel>
          <DocPanel />
        </SplitPanel>
      </Split>
    </div>
  );
}

function SplitPanel(props: PropsWithChildren) {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        overflow: "visible",
      }}
    >
      {props.children}
    </div>
  );
}
