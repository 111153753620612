import { useContext } from "react"
import { Consultant } from "../../../../../chester/src/domains/chester/consultants/types"
import { appContext } from "../useApp"
import { Table } from "antd"
import { Located } from "./types";
import { RenderLocated } from "./common";


const figureLocations = (data: Consultant[]) => {
    if (!data) return []
    return data.flatMap(d => [d.Period, d.Fee, d.Engagement, d.FiscalYear]
        .filter(d => d && "location" in d)
        .map(d => (d as Record<string, any>).location))
        .filter(Boolean) as Located["location"][]
}

const ConsultantsResults = ({ data }: { data: Consultant[] }) => {
    const context = useContext(appContext)
    const { setFocus, setHover } = context

    const datasource = data.map((d) => ({
        Name: {value: d.Name},
        Engagement: {value: d.Engagement.Value, location: (d.Engagement as Record<string, any>).location},
        Fee: {value: d.Fee.Value, location: (d.Fee as Record<string, any>).location},
        FiscalYear: {value: d.FiscalYear.Value, location: (d.FiscalYear as Record<string, any>).location},
        Period: {value: d.Period.Value, location: (d.Period as Record<string, any>).location},
    }))

    const render = (value: Located) => RenderLocated({value, setFocus, setHover})

    const columns = ["Name", "Engagement", "Fee", "FiscalYear", "Period"].map(title => ({
        title,
        dataIndex: title,
        key: title,
        render,
    }))

    return (<Table dataSource={datasource} columns={columns} />)
}

export const ConsultantsUseCase = {
    DataView: ConsultantsResults,
    figureLocations
}