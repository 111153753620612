import { Button } from "antd"
import { appContext } from "./useApp"
import { useContext } from "react"
import { Flex } from "antd"
import { FilingSelect } from "../../components/FilingSelect"
import { FilingItem } from "../chat-app/MenuPanel"
import { useElementSize } from "@custom-react-hooks/use-element-size"
import { SUPPORTED } from "./use-cases/common"

export function UseCasePanel() {
    const context = useContext(appContext)
    const doc = context.doc
    const [ref, size] = useElementSize<HTMLDivElement>();

    return (
        <>
            <div style={{ marginBottom: 20 }}>
                <FilingSelect onOpen={context.open} />
            </div>
            <div style={{ marginBottom: 20 }}>                
                {doc 
                    ? <FilingItem 
                        isSelected={true}
                        isFirst={true}
                        onClick={()=>{}}
                        close={context.close}
                        status="loaded"
                        width={size.width - 5}
                        {...doc} 
                    /> 
                : null
                }            
            </div>
            <Flex vertical gap={10}>
                {SUPPORTED.map(s => (
                    <Button key={s} onClick={() => context.setUseCase(s)} type={context.useCase === s ? "primary" : "default"} >{s}</Button>
                )
                )}
            </Flex>
        </>

    )
}

