import Markdown from "markdown-to-jsx";
import { CloseOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Button, Collapse, Flex, Tabs } from "antd";
import { useMemo, useState } from "react";
import { getProgressReports } from "../../api-clients/rag";
import { Page } from "../../components/Page";

export function ProgressReportsPage() {
  return (
    <Page>
      <ProgressReports />
    </Page>
  );
}

function useProgressReports() {
  return useQuery({
    queryKey: ["progress-reports"],
    queryFn: getProgressReports,
  });
}

function ProgressReports() {
  const reports = useProgressReports();
  const entries = useMemo(
    () => Object.entries(reports.data || {}),
    [reports.data]
  );
  const [grade, setGrade] = useState<string>();
  return (
    <div style={{ height: "100%", overflow: "auto" }}>
      <h3>Progress Reports</h3>
      <Tabs
        defaultActiveKey="1"
        items={entries.map(([file, data], i) => {
          return {
            key: `${i}`,
            label: file,
            children: (
              <div>
                <Flex gap={10}>
                  <div style={{ paddingTop: 2, fontSize: 17 }}>
                    <strong>Claude</strong>
                  </div>
                  <Button
                    type="link"
                    onClick={() => {
                      setGrade("complete");
                    }}
                  >
                    Complete: {data.results.claude.complete}
                  </Button>
                  <Button
                    type="link"
                    onClick={() => {
                      setGrade("incorrect");
                    }}
                  >
                    Incorrect: {data.results.claude.incorrect}
                  </Button>
                  <Button
                    type="link"
                    onClick={() => {
                      setGrade("missing");
                    }}
                  >
                    Missing: {data.results.claude.missing}
                  </Button>
                  <Button
                    type="link"
                    onClick={() => {
                      setGrade("partial");
                    }}
                  >
                    Partial: {data.results.claude.partial}
                  </Button>
                </Flex>
                <Flex gap={10}>
                  <div style={{ paddingTop: 2, fontSize: 17 }}>
                    <strong>Openai</strong>
                  </div>
                  <Button
                    type="link"
                    onClick={() => {
                      setGrade("complete");
                    }}
                  >
                    Complete: {data.results.openai.complete}
                  </Button>
                  <Button
                    type="link"
                    onClick={() => {
                      setGrade("incorrect");
                    }}
                  >
                    Incorrect: {data.results.openai.incorrect}
                  </Button>
                  <Button
                    type="link"
                    onClick={() => {
                      setGrade("missing");
                    }}
                  >
                    Missing: {data.results.openai.missing}
                  </Button>
                  <Button
                    type="link"
                    onClick={() => {
                      setGrade("partial");
                    }}
                  >
                    Partial: {data.results.openai.partial}
                  </Button>
                </Flex>
                {grade && (
                  <Button onClick={() => setGrade(undefined)}>
                    {grade} <CloseOutlined />
                  </Button>
                )}
                <ul>
                  {data.submissions
                    .filter((submission) => {
                      // nothing specified, show it.
                      if (!grade) return true;
                      // engine and status match
                      return (
                        submission.claude.grade === grade ||
                        submission.openai.grade === grade
                      );
                    })
                    .map((submission, i) => (
                      <li key={i} style={{ margin: 20 }}>
                        <strong>{submission.question}</strong>
                        <Flex style={{ width: "100%" }}>
                          <div style={{ flex: 1 }}>
                            <Collapse
                              items={[
                                {
                                  key: "1",
                                  label: "Accepted Answer",
                                  children: (
                                    <div className="response-markdown">
                                      <Markdown>{submission.answer}</Markdown>
                                    </div>
                                  ),
                                },
                              ]}
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <Collapse
                              items={[
                                {
                                  key: "1",
                                  label: `Claude Answer (${submission.claude.grade.toUpperCase()})`,
                                  children: (
                                    <div className="response-markdown">
                                      <Markdown>
                                        {submission.claude.answer}
                                      </Markdown>
                                    </div>
                                  ),
                                },
                              ]}
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <Collapse
                              items={[
                                {
                                  key: "1",
                                  label: `Openai Answer (${submission.openai.grade.toUpperCase()})`,
                                  children: (
                                    <div className="response-markdown">
                                      <Markdown>
                                        {submission.openai.answer}
                                      </Markdown>
                                    </div>
                                  ),
                                },
                              ]}
                            />
                          </div>
                        </Flex>
                      </li>
                    ))}
                </ul>
              </div>
            ),
          };
        })}
      />
    </div>
  );
}
