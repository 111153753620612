import * as ScrollArea from "@radix-ui/react-scroll-area";
import { LoadingOutlined } from "@ant-design/icons";
import { Empty, Flex, theme } from "antd";
import { use8k } from "./use8k";
import { appContext } from "./useApp";
import { useContext, useEffect } from "react";

export function DocPanel() {
  const { token } = theme.useToken();
  const context = useContext(appContext)
  const { doc } = context

  const hasDoc = Boolean(doc);
  return hasDoc ? (
    <DocViewerPanel />
  ) : (
    <Flex
      align="center"
      justify="center"
      style={{
        height: "100%",
        backgroundColor: `${token.colorBgLayout}99`,
      }}
    >
      <Empty description="No filing selected" />
    </Flex>
  );
}

// supports 
// "T123" 123rd table in doc
// "div_23", 23rd div in doc
// "p_23_12" text element in p (offset 12)
const locateElem = (location: string): Located | undefined => {
  const $el = document.querySelector("#document-html"); // your root
  if (!$el) return
  if (!location) return
  if (location.includes("_")) {
    const [tag, idx, offsetStr] = location.split("_")
    if (Number(idx) === 0) return
    const elems = $el.querySelectorAll(tag)
    const elem = elems[Number(idx) - 1]
    if (offsetStr) {
      // figure the right text node
      // first gather the child text nodes. we assume the offset is not relative to nested text nodes.
      const textNodes: Text[] = []
      elem?.childNodes!.forEach(node => {
        if (node.nodeType === Node.TEXT_NODE) {
          textNodes.push(node as Text)
        }
      })

      // find the text node that contains the offset
      const offset = Number(offsetStr)
      let cumOffset = 0
      for (const node of textNodes) {
        const text = node.data
        if (cumOffset + text.length >= offset) {
          return {text:node, offset}
        }
        cumOffset += text.length
      }
      // couldn't figure out the offset
      return { elem}

    } else {
      return { elem }
    }
  } else if (location.startsWith("T")) {
    const idx = Number(location.slice(1)) - 1 // locator is 1-indexed
    const tables = $el.querySelectorAll('table')
    return { elem: tables[idx] }
  }
}

type Located = {
  elem: Element
} | {
  text: Text
  offset: number
}



// typescript still does not support Highlight api https://github.com/microsoft/TypeScript/issues/53003
declare class Highlight {
  constructor(...range: Range[]);
}
const MYCSS = CSS as any

function DocViewerPanel() {
  const { token } = theme.useToken();
  const context = useContext(appContext)
  const { doc } = context
  if (!doc) throw new Error("Expected doc");
  const viewer = use8k(doc!)

  // ------------------ highlight locations
  const highlights = context.highlights

  const figureRangeForLocator = (location: {startLocator: string | undefined, endLocator: string | undefined}) => {
    if (!location.startLocator || !location.endLocator) return
    const start = locateElem(location.startLocator)
      const finish = locateElem(location.endLocator)
      if (!start || !finish) return
      const range = new Range()
      if ("text" in start) {
        range.setStart(start.text, start.offset)
      } else {
        range.setStart(start.elem, 0)
      }
      if ("text" in finish) {
        range.setEnd(finish.text, finish.offset)
      } else {
        range.setEnd(finish.elem, 0)
      }
      return range
  }
  
  useEffect(() => {
    // remove previous highlights
    MYCSS.highlights.clear()

    if (highlights.length === 0) return
    const ranges = []
    for (const highlight of highlights) {
      const range = figureRangeForLocator(highlight)
      if (range) {
        ranges.push(range)
      }
    }
    const cssHighlight = new Highlight(...ranges)
    MYCSS.highlights.set("highlights", cssHighlight)

  }, [highlights])


  //------------- scroll to location
  const show = (location: string | undefined) => {
    if (!location) return
    const located = locateElem(location)
    if (located) {
      const elem = "text" in located ? located.text.parentElement : located.elem
      if (elem) {
        elem.scrollIntoView({ block: "center", behavior: "smooth" })
      }
    }
  }

  useEffect(() => {
    if (context.focused) {
      show(context.focused.locator)
    }
  }, [viewer.ready, context.focused])



  useEffect(() => {
    const $el = document.querySelector("#document-html"); // your root
    if (!$el) return;
    $el
      .querySelectorAll(".hoverborder")
      .forEach((el) => el.classList.remove("hoverborder"));
    const locations = context.hovered
    if (!locations || locations.length === 0) return

    // super highlight

    const ranges = []
    for (const location of locations) {
      const range = figureRangeForLocator(location)
      if (range) {
        ranges.push(range)
      }
    }
    const cssHighlight = new Highlight(...ranges)
    MYCSS.highlights.delete("highlights_2")
    MYCSS.highlights.set("highlights_2", cssHighlight)

    const located = locateElem(locations.at(0)!.startLocator!)
    if (located) {
      const elem = "text" in located ? located.text.parentElement : located.elem
      if (elem) {
        elem.scrollIntoView({ block: "center", behavior: "smooth" })
      }
    }

  }, [viewer.ready, context.hovered]);


  if (!viewer.ready)
    return (
      <Flex justify="center" align="center" style={{ height: "100%" }}>
        <LoadingOutlined />
      </Flex>
    );

  return (
    <Flex
      vertical
      style={{
        height: "100%",
        border: `solid 1px ${token.colorBgContainer}`,
        borderRadius: 15,
        boxShadow: token.boxShadowTertiary,
      }}
    >

      <div
        style={{
          overflow: "hidden",
          height: "100%",
          backgroundColor: token.colorBgContainer,
          borderRadius: "0 0 15px 15px",
        }}
      >
        <ScrollArea.Root className="ScrollAreaRoot">
          <ScrollArea.Viewport className="ScrollAreaViewport">
            <div id="document-html"
              style={{
                padding: 20,
              }}
              dangerouslySetInnerHTML={{ __html: viewer.html }}
            />
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar
            className="ScrollAreaScrollbar"
            orientation="vertical"
          >
            <ScrollArea.Thumb className="ScrollAreaThumb" />
          </ScrollArea.Scrollbar>
          <ScrollArea.Scrollbar
            className="ScrollAreaScrollbar"
            orientation="horizontal"
          >
            <ScrollArea.Thumb className="ScrollAreaThumb" />
          </ScrollArea.Scrollbar>
        </ScrollArea.Root>
      </div>
    </Flex>
  );
}

const minimalNodesBetween = (startElem: Element, endElem: Element) => {
  if (!startElem) {
    return []
  }
  const range = document.createRange();
  range.setStart(startElem!, 0);
  range.setEnd(endElem!, 0);
  const ancestor = range.commonAncestorContainer
  const upTo = (elem: Element) => {
    const res = []
    let current = elem
    while (current.parentElement && current !== ancestor) {
      current = current.parentElement
      res.push(current)
    }
    return res
  }
  const endAncestors = upTo(endElem!)
  const elems = [endElem!]
  let current = startElem!
  while (true) {
    if (current === endElem) break
    elems.push(current)
    let found = false
    while (current.nextSibling) {
      const sibling = current.nextSibling
      if (endAncestors.includes(sibling as Element)) {
        found = true
        current = sibling as Element
        do {
          current = sibling.firstChild as Element
        } while (endAncestors.includes(current))
        break
      } else {
        elems.push(sibling as Element)
      }
    }
    if (!found) {
      current = current.parentElement!
    }
  }
  return elems
}