import { Flex, theme } from "antd";
import { useContext } from "react";
import { FilingSelect } from "../../../components/FilingSelect";
import { chatContext } from "../useChatApp";

export function ChatStartScreen() {
  const { token } = theme.useToken();
  const context = useContext(chatContext);
  return (
    <Flex justify="center">
      <div
        style={{
          marginTop: 100,
          borderRadius: 15,
          padding: 15,
          paddingBottom: 25,
          backgroundColor: token.colorBgContainer,
          boxShadow: token.boxShadowTertiary,
        }}
      >
        <div style={{ marginBottom: 4, width: 420 }}>
          Start by selecting a document
        </div>
        <FilingSelect onOpen={context.open} />
      </div>
    </Flex>
  );
}
