import { useContext } from "react"
import { STILevels, STIPrinciple } from "../../../../../chester/src/domains/chester/sti/zod"
import { appContext } from "../useApp"
import { Table } from "antd"
import { Located } from "./types";
import { RenderLocated } from "./common";

type STI = STILevels & STIPrinciple;

const figureLocations = (data: STI[]) => {
    if (!data) return []
    return data.flatMap(d => [d.applicableTo, d.basePayTarget, d.targetThreshold, d.targetMaximum, d.peerGroupTarget]
        .filter(d => d && "location" in d)
        .map(d => (d as Record<string, any>).location))
        .filter(Boolean) as Located["location"][]
}

const StiResults = ({ data }: { data: STI[] }) => {
    const context = useContext(appContext)
    const { setFocus, setHover } = context

    const datasource = data.map((d) => ({
        applicableTo: {
            value: d.applicableTo && Object.keys(d.applicableTo).length > 0
                ? "officerName" in d.applicableTo
                    ? d.applicableTo.officerName
                    : d.applicableTo.roles.join(", ")
                : "",
        },
        basePayTarget: {
            value: d.basePayTarget?.value
                ? typeof (d.basePayTarget?.value) === "number"
                    ? d.basePayTarget?.value
                    : `${d.basePayTarget.value.from} - ${d.basePayTarget.value.to}`
                : "",
            location: (d.basePayTarget as Record<string, any>)?.location
        },
        targetThreshold: {
            value: d.targetThreshold?.value,
            location: (d.targetThreshold as Record<string, any>)?.location
        },
        targetMaximum: {
            value: d.targetMaximum?.value,
            location: (d.targetMaximum as Record<string, any>)?.location
        },
        peerGroupTarget: {
            value: d.peerGroupTarget
                ? typeof (d.peerGroupTarget?.value) == "string" || typeof (d.peerGroupTarget?.value) === "number"
                    ? d.peerGroupTarget?.value
                    : `${d.peerGroupTarget.value.from} - ${d.peerGroupTarget.value.to} ${d.peerGroupTarget.value.kind === "percentile" ? "percentile" : "% of median"}`
                : "",
            location: (d.peerGroupTarget as Record<string, any>)?.location
        },
    }))


    const render = (value: Located) => RenderLocated({value, setFocus, setHover})

    const columns = ["applicableTo", "peerGroupTarget", "basePayTarget", "targetThreshold", "targetMaximum"].map(title => ({
        title,
        dataIndex: title,
        key: title,
        render,
    }))
    return (<Table dataSource={datasource} columns={columns} />)
}

export const StiUseCase = {
    DataView: StiResults,
    figureLocations
}