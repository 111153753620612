import { Page } from "../../components/Page";
import { ViewerScreen } from "./screens/ViewerScreen";

export function DevViewerPage() {
  return (
    <Page>
      <ViewerScreen />
    </Page>
  );
}
