import { Page } from "../../components/Page";
import { InputScreen } from "./screens/input";
import { ViewerScreen } from "./screens/view";
import { useApp } from "./useApp";
export function Dev8k502Page() {

  const context = useApp()
  const hasDoc = context.doc!!
  return (
    <Page>
      {hasDoc ? <ViewerScreen/> : <InputScreen/>}
    </Page>
  );
}
