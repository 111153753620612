import { useEffect } from "react";
import { Link } from "react-router-dom";

// This page doesn't work in prod.
// When the app used the hash router, this worked by redirecting to "/#logout"
// The docs discourage using the hash router, and it also prevented the doc viewer
// from workings out of the box. That was a good reason to disable it.
// Now we use a browser router, which works perfectly in dev. In prod, Koa
// is not yet set up as a push-state server for the app, so paths don't work
// when requested from the browser to the server. Until the push state server is
// set up, this page never gets called. It is left for completeness, and the assumption
// that the push state server will be implemented sooner than later.
export function LogoutPage() {
  useEffect(() => {
    localStorage.removeItem("clerk-auth-token");
  }, []);
  return (
    <div>
      <Link to="/">back to app</Link>
    </div>
  );
}
