import { useContext } from "react"
import { appContext } from "../useApp"
import { Table } from "antd"
import { Located } from "./types";
import { RenderLocated } from "./common";
import { LTITargets, LTIPrinciple, LTIPayouts } from "../../../../../chester/src/domains/chester/lti/zod";

type LTI = {
    levels: LTITargets[],
    principle: LTIPrinciple[],
    payouts: LTIPayouts[]
}

const figureLocations = (data: LTI) => {
    if (!data) return []
    return  [
        ...data.principle.map(p => p.peerGroupTarget),
        ...data.levels.map(level => level.compensationTarget),
        ...data.payouts.map(payout => payout.rule)
    ]
        .filter(d => d && "location" in d)
        .map(d => (d as Record<string, any>).location)
        .filter(Boolean) as Located["location"][]
}

const LtiResults = ({ data }: { data: LTI }) => {
    const context = useContext(appContext)
    return (<>
    principle
        <LtiPrinciple data={data.principle} />
    targets
        <CompensationTargetTable data={data.levels} />
    payouts
        <CompensationPayoutsTable data={data.payouts} />
    </>)
}



const LtiPrinciple = ({data}:{data: LTIPrinciple[]}) => {
    const context = useContext(appContext)
    const { setFocus, setHover } = context

    const datasource = data.map((d,idx) => ({
        key: idx,
        ...d,
        applicableTo: {
            value: d.applicableTo && Object.keys(d.applicableTo).length > 0
                ? "officerName" in d.applicableTo
                    ? d.applicableTo.officerName
                    : d.applicableTo.roles.join(", ")
                : "",
        },
        peerGroupTarget: {
            value: d.peerGroupTarget
                ? typeof (d.peerGroupTarget?.value) == "string" || typeof (d.peerGroupTarget?.value) === "number"
                    ? d.peerGroupTarget?.value
                    : `${d.peerGroupTarget.value.from} - ${d.peerGroupTarget.value.to} ${d.peerGroupTarget.value.kind === "percentile" ? "percentile" : "percentage of median"}`
                : "",
            location: (d.peerGroupTarget as Record<string, any>)?.location
        },
        
    }))
    const render = (value: Located) => RenderLocated({value, setFocus, setHover})

    const columns = ["applicableTo", "peerGroupTarget"].map(title => ({
        title,
        dataIndex: title,
        key: title,
        render,
    }))

    return (
    <Table 
        dataSource={datasource} 
        columns={columns}  
        pagination={false}      
    />)
}


const CompensationPayoutsTable = ({data}: {data: LTIPayouts[]}) => {
    const context = useContext(appContext)
    const { setFocus, setHover } = context
    const render = (value: Located) => <RenderLocated 
        value={value} 
        setFocus={setFocus} 
        setHover={setHover} />

    const datasource = data.map((d,idx) => ({     
        ...d,
        applicableTo: {
            value: d.applicableTo && Object.keys(d.applicableTo).length > 0
                ? "officerName" in d.applicableTo
                    ? d.applicableTo.officerName
                    : d.applicableTo.roles.join(", ")
                : "",
        },

        kind: {
            value: d.rule.kind || "",
            referenceText: d.rule.referenceText || "",
            location: (d as Record<string, any>)?.location
        },
        threshold: {
            value: d.rule.threshold || "",
            referenceText: d.rule.referenceText || "",
            location: (d.rule as Record<string, any>)?.location
        },
        maximum: {
            value: d.rule.maximum || "",
            referenceText: d.rule.referenceText || "",
            location: (d.rule as Record<string, any>)?.location
        },
        key: idx
    }))

    const columns = ["applicableTo", "kind", "threshold", "maximum"].map(title => ({
        title,
        dataIndex: title,
        key: title,
        render,
    }))

    return <Table dataSource={datasource} columns={columns} pagination={false} />
}

const CompensationTargetTable = ({data}: {data: LTITargets[]}) => {
    const context = useContext(appContext)
    const { setFocus, setHover } = context
    const render = (value: Located) => <RenderLocated 
        value={value} 
        setFocus={setFocus} 
        setHover={setHover} />

    const datasource = data.map((d,idx) => ({    
        ...d,
        applicableTo: {
            value: d.applicableTo && Object.keys(d.applicableTo).length > 0
                ? "officerName" in d.applicableTo
                    ? d.applicableTo.officerName
                    : d.applicableTo.roles.join(", ")
                : "",
        },            
        value: {
            value :(typeof d.compensationTarget.value === "object" ? `${d.compensationTarget.value.from} - ${d.compensationTarget.value.to}` : d.compensationTarget.value) || "",
            referenceText: d.compensationTarget.referenceText || "",
            location: (d.compensationTarget as Record<string, any>)?.location
        },
        kind: {
            value: d.compensationTarget.kind || "",
            referenceText: d.compensationTarget.referenceText || "",
            location: (d.compensationTarget as Record<string, any>)?.location
        },
        unit: {
            value: d.compensationTarget.unit || "",
            referenceText: d.compensationTarget.referenceText || "",
            location: (d.compensationTarget as Record<string, any>)?.location
        }
    }))

    const columns = ["applicableTo", "kind", "value", "unit"].map(title => ({
        title,
        dataIndex: title,
        key: title,
        render,
    }))

    return <Table dataSource={datasource} columns={columns} pagination={false} />
}

export const LtiUseCase = {
    DataView: LtiResults,
    figureLocations
}