import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getDocStatus } from "../../api-clients";

export function useDocStatus(
  doc: {
    ticker: string;
    form: string;
    year: number;
  },
  { onLoaded, onLoading }: Partial<{ onLoaded(): void; onLoading(): void }> = {}
): string {
  const [status, setStatus] = useState("loading");
  const { mutateAsync } = useMutation({
    mutationFn: async () => getDocStatus(doc),
  });
  useEffect(() => {
    let int = undefined as unknown as ReturnType<typeof setInterval>;
    function loadDoc() {
      if (status === "loading") {
        if (onLoading) onLoading();
        mutateAsync().then((doc) => {
          setStatus(doc.status);
        });
      } else {
        if (int) clearInterval(int);
        if (onLoaded) onLoaded();
      }
    }
    loadDoc();
    int = setInterval(loadDoc, 2500);
    return () => clearInterval(int);
  }, [mutateAsync, status, onLoaded, onLoading]);
  return status;
}
