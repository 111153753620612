import createDOMPurify from "dompurify";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { process8k502 } from "../../api-clients";
import { getFilingByAccessionNumber } from "../../api-clients/rag";

export function use8k(doc: {
  ticker: string;
  accessionNumber: string;
} | undefined) {

  const query = useQuery({
    queryKey: ["filing", doc],
    queryFn: async () => getFilingByAccessionNumber(doc!)
  });

  const process = useQuery({
    queryKey: ["process", doc],
    queryFn: async () => doc ? process8k502(doc) : undefined,
  });

  const [DOMPurify] = useState(createDOMPurify(window));
  const safe = DOMPurify.sanitize(query.data?.html || "");
  return { html: safe, ready: query.isFetched, data: process.data };
}
