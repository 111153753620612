import createDOMPurify from "dompurify";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getFiling } from "../../api-clients/rag";

export function useDocLoader(doc: {
  ticker: string;
  year: number;
  form: string;
  accessionNumber: string;
} | undefined) {

  const query = useQuery({
    queryKey: ["filing", doc],
    queryFn: async () => getFiling(doc!)
  });


  const [DOMPurify] = useState(createDOMPurify(window));
  const safe = DOMPurify.sanitize(query.data?.html || "");
  return { html: safe, ready: query.isFetched };
}
